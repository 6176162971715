.Reviews{
    display: none;
}

.Reviews.loaded{
    display: block;
}

.Reviews .content{
    display: block;
    overflow: hidden;
    padding-bottom: 26px;
}

.Reviews .slick-arrow.slick-prev::before,
.Reviews .slick-arrow.slick-next::before {
    display: none;
}

.Reviews .Review{
    direction: rtl;
    margin-left: 10px;
    margin-right: 10px;
}

.Reviews .slick-track{
    float:unset;
    overflow: hidden;
    padding-bottom: 20px;
}

@media only screen and (min-width: 760px) {
    .Reviews .slick-arrow.slick-prev::before,
    .Reviews .slick-arrow.slick-next::before {
        display: block;
        color:#127997
    }

    .Reviews .content{
        padding-left: 40px;
        padding-right: 40px;
        width: calc(100% - 80px);
    }
}