.Review {
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    width: calc(100% - 40px);
    height: 100%;
    display: inline-flex;
}

.Review .avatar {
    width: 60px;
}

.Review .avatar img {
    border-radius: 30px;
    width: 40px;
    height: 40px;
}

.Review .avatar div {
    border-radius: 30px;
    width: 40px;
    height: 40px;
    background-color: #5F7D95;
    color: white;
    text-align: center;
    margin-right: 10px;
    font-size: x-large;
    font-weight: bold;
    padding-top: 1px;
}

.Review .details {
    flex: 1;
    text-align: right;
}

.Review .details .title {
    width: 100%;
    font-weight: bold;
    color: blue;
    height: 25px;
}

.Review .details .star-rating {
    height: 25px;
}

.Review .details .comment {
    width: auto;
    color: gray;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 50px)
}