.App {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.App .AppHeader {
    position: absolute;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    z-index: 1;
    direction: rtl;
    text-align: right;
}

.App .App-body {
    width: 100%;
    min-height: calc(100% - 49px);
    z-index: 0;
    direction: rtl;
    text-align: center;
}

.App .AppFooter {
    /*height: 50px;*/
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.24), 0 -1px 2px rgba(0, 0, 0, 0.24);
    z-index: 1;
    direction: rtl
    /*text-align: right;*/
}

.App .page-row {
    width: calc(100% - 30px);
    padding-right: 15px;
    padding-left: 15px;
}

.App .page-row.extra-padding-top {
    padding-top: 30px;
}

.App .page-row.extra-padding-bottom {
    padding-bottom: 30px;
}

.App .page-row.extra-padding {
    padding-top: 30px;
    padding-bottom: 30px;
}

.WhatsApp {
    position: fixed;
    bottom: 30px;
    left: 30px;
    width: 50px;
    height: 50px;
    z-index: 2;
}

@media only screen and (min-width: 700px) {
    .App .page-row {
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .App .AppHeader {
        box-shadow: unset;
    }
}

@media only screen and (min-width: 800px) {
    .App .page-row {
        width: 80%;
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media only screen and (min-width: 900px) {
    .App .page-row {
        width: 70%;
        padding-left: 15%;
        padding-right: 15%;
    }
}