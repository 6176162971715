.theme-main-color {
    color: #127997;
    fill: #127997;
}

.theme-color.main {
    color: #127997;
    fill: #127997;
}

.theme-text-color.bright {
    color: white;
}

.theme-text-color.drak {
    color: #2e2e3d;
}

.theme-main-color-background {
    color: white;
    background-color: #127997;
    border-color: #127997;
    fill: #127997;
}

.theme-page-color,
.theme-page-row-color {
    background-color: whitesmoke;
}

.theme-page-row-color.alt {
    background-color: white;
}

.theme-link {
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    color: #127997;
}

.theme-link-h2 {
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    color: #127997;
    margin: 8px;
}

.theme-link:hover {
    opacity: 0.5;
}

.theme-start-process-button {
    color: white;
    /*font-weight: 200;*/
    font-size: x-large;
    padding: 10px 20px;
    border-radius: 30px;
    background-color: transparent;
    /*  linear-gradient(65deg, #01BA9F 0, #72DDCD 100%);*/
    background-origin: border-box;
    border: solid 2px white;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.24), 0px 3px 3px rgba(0, 0, 0, 0.24);
}

.theme-start-process-button.alt {
    background-color: #01BA9F;
}

.underline {
    text-decoration: underline;
}