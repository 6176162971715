.inputText {
    /*remove arrows from spin edit in firefox*/
    -moz-appearance: textfield;
    padding: 12px 20px;
    margin: 8px 8px;
    display: inline-block;
    border: 1px solid;
    border-radius: 50px;
    box-sizing: border-box;
    font-size: larger;
    outline: none;
}