body {
    margin: 0;
    font-family: "Assistant", Sans-serif;
    /*"Ploni", "Heebo", sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-size-adjust: none;
    /*change font size on mobile*/
    font-weight: 600;
}

div {
    float: right;
    width: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
    -webkit-appearance: none;
    font-family: "Assistant", Sans-serif;
    background-color: transparent;
    display: inline-block;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 1em;
}


/*remove arrows from spin edit from all web browsers*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/*
input[type=text],
input[type=number] {
    /*remove arrows from spin edit in firefox*/


/*-moz-appearance: textfield;
    padding: 12px 20px;
    margin: 8px 8px;
    display: inline-block;
    border: 1px solid;
    border-radius: 50px;
    box-sizing: border-box;
    font-size: larger;
    outline: none;
}
*/

a {
    text-decoration: solid;
}

ul {
    list-style-type: none;
    display: contents;
}

ul li {
    display: flex;
    cursor: pointer;
}